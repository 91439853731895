<template>
  <section class="hero is-medium is-info">
    <div class="hero-body">
      <p class="title">
        Sengoku
      </p>
      <p class="subtitle">
        知网期刊文章 PDF 链接批量导出器
      </p>
    </div>
  </section>  
</template>

<script>
export default {
  name: "Hero"
}
</script>

<style lang="stylus" scoped>

</style>