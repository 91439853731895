<template>
  <div class="home">
    <section class="section">
      <div class="container">
        <b-field grouped>
          <b-field label="期刊链接" expanded>
            <b-input
              v-model="journalLink"
              placeholder="https://navi.cnki.net/KNavi/JournalDetail?pcode=CJFD&pykm=ZGQL&Year=&Issue="
            ></b-input>
          </b-field>
          <b-field label="年份" v-if="journalYearList">
            <b-select v-model="year" placeholder="选择年份">
              <option v-for="year in years" :value="String(year)" :key="year">
                {{ year }}
              </option>
            </b-select>
          </b-field>
          <b-field label="卷号" v-if="journalYearList">
            <b-select v-model="issue" placeholder="当年第几期" :disabled="!year">
              <option
                v-for="issue in journalYearList.result[year]"
                :value="issue"
                :key="issue"
              >
                {{ issue }}
              </option>
            </b-select>
          </b-field>
        </b-field>

        <div class="buttons is-right">
          <b-button
            type="is-light"
            :loading="loadingYearList"
            @click="getJournalYearList"
            :disabled="!journalLink"
          >
            获取期刊信息
          </b-button>
          <b-button
            type="is-light"
            :loading="loading"
            @click="getAllLinks"
            :disabled="!(journalYearList && year && issue)"
          >
            获取链接
          </b-button>
        </div>
      </div>
    </section>

    <section class="section favourite" v-show="!remoteData">
      <div class="container content">
        <h3 class="title">酱瓜最爱</h3>
        <b-button
          type="is-light"
          class="fav-button"
          v-for="(value, key) in favourite"
          @click="journalLink = value"
          :key="key"
        >
          {{ key }}
        </b-button>
      </div>
    </section>

    <section class="section result" v-if="remoteData">
      <div class="container content">
        <h3 class="title">导出结果</h3>
        <ol>
          <li>
            <a
              :href="this.remoteData.toc"
              target="_blank"
              rel="noopener noreferrer"
              >目录页 [KDH]</a
            >
          </li>
          <li v-for="a in this.remoteData.articles" :key="a.url">
            <a :href="a.pdfUrl" target="_blank" rel="noopener noreferrer"
              >{{ a.title }} [PDF]</a
            >
          </li>
        </ol>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pykm: "",
      pcode: "",
      year: "",
      issue: "",
      journalLink: "",
      remoteData: false,
      journalYearList: false,
      errored: false,
      loading: false,
      loadingYearList: false,
      favourite: {
        中国青年研究: "https://navi.cnki.net/KNavi/JournalDetail?pcode=CJFD&pykm=ZGQL&Year=&Issue=",
        社会科学文摘: "https://navi.cnki.net/KNavi/JournalDetail?pcode=CJFD&pykm=SHGC&Year=&Issue=",
        经济研究: "https://navi.cnki.net/KNavi/JournalDetail?pcode=CJFD&pykm=JJYJ&Year=&Issue=",
        中国人民大学学报: "https://navi.cnki.net/knavi/JournalDetail?pcode=CJFD&pykm=ZRDX",
        中国人民大学教育学刊: "https://navi.cnki.net/knavi/JournalDetail?pcode=CJFD&pykm=RDXK",
        新闻与传播研究: "https://navi.cnki.net/KNavi/JournalDetail?pcode=CJFD&pykm=YANJ&Year=&Issue=",
        社会学研究: "https://navi.cnki.net/KNavi/JournalDetail?pcode=CJFD&pykm=SHXJ&Year=&Issue=",
        新闻与传播评论: "https://navi.cnki.net/KNavi/JournalDetail?pcode=CJFD&pykm=WHDS&Year=&Issue="
      },
    };
  },
  computed: {
    years() {
      const start = Number(this.journalYearList.min);
      const end = Number(this.journalYearList.max);
      return Array(end - start + 1)
        .fill(start)
        .map((el, i) => start + i)
        .reverse();
    },
  },
  methods: {
    getAllLinks() {
      this.loading = true;
      this.remoteData = false;
      const url = `${process.env.VUE_APP_API}/getAllLinks?pykm=${this.pykm}&year=${this.year}&issue=${this.issue}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.remoteData = response.data;
          const hash = this.pykm + this.year + this.issue
          localStorage.setItem(hash, JSON.stringify(response.data))
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getJournalYearList() {
      this.loadingYearList = true;
      const url_obj = parseURL(this.journalLink);
      this.pykm = url_obj.params.pykm;
      this.pcode = url_obj.params.pcode;

      const url = `${process.env.VUE_APP_API}/getJournalYearList?pykm=${this.pykm}&pcode=${this.pcode}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.journalYearList = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loadingYearList = false));
    },
  },
  watch: {
    journalLink() {
      this.journalYearList = false;
      this.year = "";
      this.issue = "";
      this.remoteData = false;
    },
    year() {
      this.remoteData = false
    },
    issue() {
      this.remoteData = false
      const hash = this.pykm + this.year + this.issue
      const cached_data = localStorage.getItem(hash)
      if (cached_data) {
        this.remoteData = JSON.parse(cached_data)
      }
    }
  },
  mounted() {
    const url = `${process.env.VUE_APP_API}/`;
    this.$axios
      .get(url)
      .then(() => {
        console.log("API 正常");
      })
      .catch((error) => {
        console.log("API 异常: ", error);
      });
  },
};

function parseURL(url) {
  var a = document.createElement("a");
  a.href = url; // var a = new URL(url);
  return {
    source: url,
    protocol: a.protocol.replace(":", ""),
    host: a.hostname,
    port: a.port,
    query: a.search,
    params: (function () {
      var params = {},
        seg = a.search.replace(/^\?/, "").split("&"),
        len = seg.length,
        p;
      for (var i = 0; i < len; i++) {
        if (seg[i]) {
          p = seg[i].split("=");
          params[p[0]] = p[1];
        }
      }
      return params;
    })(),
    hash: a.hash.replace("#", ""),
    path: a.pathname.replace(/^([^/])/, "/$1"),
  };
}
</script>

<style lang="stylus" scoped>
.fav-button
  margin: 5px 20px 20px 5px
</style>