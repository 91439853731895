<template>
  <div id="app">
    <Hero/>
    <router-view />
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";

export default {
  components: {
    Hero,
  },
};
</script>

<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
